import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Table from '../components/Table';

const NewPage = ({ data, location }) => {
  const title = 'New plugins & themes';

  const themes = [];
  data.themes.nodes.forEach(function (edge) {
    edge.officialDownloads = edge.numDownloads;
    edge.numDownloads = edge.topListNumDownloads;

    themes.push(edge);
  });

  const plugins = [];
  data.plugins.nodes.forEach(function (edge) {
    edge.officialDownloads = edge.numDownloads;
    edge.numDownloads = edge.topListNumDownloads;

    plugins.push(edge);
  });

  return (
    <Layout location={location} title={title}>
      <h1>{title} </h1>
      <>
        <p>Since 2022-04-28.</p>
        {themes.length > 0 && (
          <>
            <h2>{`Themes`}</h2>
            {data?.themes?.nodes && Table({ data: themes, owner: true })}
          </>
        )}
        <h2>{`Plugins`}</h2>
        {data?.plugins?.nodes && Table({ data: plugins, owner: true })}
      </>
    </Layout>
  );
};
export const query = graphql`
  {
    plugins: allMatomoPlugin(
      filter: { isPaid: { eq: false }, topListNew: { eq: true } }
      sort: { order: DESC, fields: topListNumDownloads }
    ) {
      nodes {
        id
        idPath
        displayName
        latestVersion
        lastUpdated
        isPaid
        isCustomPlugin
        createdDateTime
        description
        displayName
        numDownloads
        topListNumDownloads
        topListNew
        repositoryUrl
        owner
      }
    }
    themes: allMatomoTheme(
      filter: { topListNew: { eq: true } }
      sort: { order: DESC, fields: topListNumDownloads }
    ) {
      nodes {
        id
        idPath
        latestVersion
        lastUpdated
        isPaid
        isCustomPlugin
        createdDateTime
        description
        displayName
        numDownloads
        topListNumDownloads
        topListNew
        repositoryUrl
        owner
      }
    }
  }
`;

export default NewPage;
